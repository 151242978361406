window.addEventListener("DOMContentLoaded", () => {
    try {
        let burger = document.querySelector("#burger");
        let headerNavs = document.querySelector(".header__navs");
        burger.addEventListener("click", (e) => {
            e.preventDefault();
            burger.classList.toggle("show");
            headerNavs.classList.toggle("show");
        })
    } catch (e) {
        console.log(e + "header");
    }

    try {

        window.addEventListener('scroll', () => {
            let scrollDistance = window.scrollY;
            console.log(scrollDistance);
            if(scrollDistance > 50){
                document.querySelector("#header").classList.add("scrolling");
            } else {
                document.querySelector("#header").classList.remove("scrolling");
            }


        });

    } catch (e) {
        console.log(e + "scroll");
    }

    try {

        let elem = document.querySelector('#microsoft-profile .items');
        let msnry = new Masonry( elem, {
            // options
            itemSelector: '#microsoft-profile .items__item',
            percentPosition: true
        });

    } catch (e) {
        console.log(e + "mansory");
    }

    try {

        let bodyPage = document.querySelector('body'),
            modal = document.querySelector('#modal'),
            modalClose = document.querySelector('.modal-close'),
            showModalForm = document.querySelectorAll('.show-modal-form');

        //console.log(showModalForm);

        showModalForm.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();
                bodyPage.classList.add("modal-show");
                modal.classList.add("show");

            })
        })

        modalClose.addEventListener("click", (e) => {
            e.preventDefault();
            bodyPage.classList.remove("modal-show");
            modal.classList.remove("show");
        })

    } catch (e) {
        console.log(e + "modal");
    }

    try {
        const swiper = new Swiper('.sources__items', {
            slidesPerView: 1.25,
            //width: 250,
            spaceBetween: 30,
            breakpoints: {
                768: {
                    slidesPerView: 2.2,
                    spaceBetween: 50,
                    width: null,
                    freeMode: true,
                    grid: {
                        fill: "column",
                        rows: 2
                    }
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                    width: null,
                    freeMode: true,
                    grid: {
                        fill: "column",
                        rows: 2
                    }
                }
            },
            pagination: {
                el: ".navs-dots",
                clickable: true,
            },
            navigation: {
                nextEl: '.navs-arrows--next',
                prevEl: '.navs-arrows--prev',
            }
        });
    } catch (e) {
        console.log(e);
    }
});
